<template>
    <div class="type_area">
        <div style="display:flex;padding:20px 0;"> 
            <el-menu  style="width:200px;" class="el-menu-vertical-demo" :unique-opened="true" @open="handleOpen" @close="handleClose"  background-color="#1a9dff" text-color="#fff" active-text-color="#ffd04b">
                <el-submenu :index="''+index" v-for="(item,index) in sideBar" :key="index">
                    <template slot="title">
                        <span>{{item.categoryName}}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item v-for="(items,indexs) in item.childen" @click="handleSelect(items)" :key="indexs" :index="index+'-'+indexs">{{items.title}}</el-menu-item>
                        <!-- <el-menu-item index="1-2">选项2</el-menu-item> -->
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
            <div style="flex:1; margin-left:20px; padding:20px; background:#fff; min-height:300px;">
               
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'helpCenter',
    data() {
        return {
            sideBar: []
        }
    },
    created() {
        this.getPcArticle()

    },
    mounted() {
    },
    methods: {
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleSelect(key) {
            console.log(key);
        },
        // 获取底部文章
        getPcArticle() {
            this.$api.pcArticle().then((res) => {
                if (res.data.code === 200) {
                    this.sideBar = res.data.data

                }
            });
        },
    }
}
</script>

<style>

</style>